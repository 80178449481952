import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">
                Contarct Logistics
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled">
                  Contarct Logistics
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5 pt-4">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Contarct Logistics.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Contarct Logistics</h1>
              <p>
                We firmly believe in the strength of collaboration and are
                dedicated to working closely with our customers, starting from
                the initial discussions and continuing through to the final
                implementation stage. Our objective is to formulate a
                sustainable logistics solution strategy that is precisely
                customized to meet your distinct requirements.
              </p>

              <p>
                To accomplish this, we utilize our well-established systems,
                methodologies, and resources. With these tools at our disposal,
                we can promptly and effectively assess your current business
                situation and anticipate future scenarios. Throughout this
                process, we consider your specific requests while also applying
                our extensive experience and knowledge to create the most
                suitable solution. At {companyname}, we recognize that a
                one-size-fits-all approach is not effective, and therefore, we
                prioritize customization.
              </p>

              <p>
                We understand that entrusting your business operations to a
                logistics service provider or initiating a new relationship may
                carry inherent risks. However, we have developed proven project
                management tools and methodologies, supported by a team of
                experienced professionals, to minimize these risks. We
                diligently work to de-risk all project work streams, ensuring a
                seamless implementation process that has no adverse impact on
                your business operations.
              </p>

              <p>
                At {companyname}, we place great value on efficient
                communication and have established well-structured
                organizational systems to offer you a straightforward and rapid
                communication channel. This ensures that your business continues
                to operate smoothly, with any concerns or inquiries being
                promptly addressed.
              </p>

              <p>
                We are enthusiastic about the opportunity to collaborate with
                you and establish a mutually beneficial business relationship.
                Let's work together to create successful logistics solutions.
              </p>
            </div>
            {/* */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Our Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link to="/Air" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link to="/Sea" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Sea Freight
                </Link>
                <Link to="/Road" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Road Freight
                </Link>
                <Link to="/Rail" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Rail Freight
                </Link>
                <Link to="/Contarct" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Contarct Logistics
                </Link>
                <Link to="/Supply" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Supply Chain Solution
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
