import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">
          Cargo Insurance
        </h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <Link to="/Home">Home</Link>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0">Cargo Insurance</h6>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* Cargo Insurance Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Cargo Insurance.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Cargo Insurance</h1>
              <p>
                {companyname} places immense value and significance on the
                shipments we handle. We are acutely aware of the worth of our
                clients' cargo, and as a result, we have diligently chosen a
                robust insurance plan to safeguard their goods against the
                inherent risks associated with transportation. This cargo
                insurance is an integral part of our comprehensive service
                cycle, providing essential value-add to ensure the protection
                and well-being of our clients' valuable shipments.
                <p>
                  Our commitment to prioritizing our clients' rights and
                  interests is unwavering. Thanks to our strong track record and
                  global presence, we are well-equipped to negotiate competitive
                  and comprehensive cargo insurance policies that align with our
                  clients' needs. Our team of seasoned specialists is readily
                  available to address inquiries and provide expert guidance on
                  various aspects of cargo insurance.
                </p>
                {companyname} exclusively partners with reputable foreign
                carriers, assuring our clients of reliable and trustworthy
                services. In the event of any covered risks resulting in damage
                or loss to the cargo, a cargo insurance policy serves as a
                crucial safeguard, reimbursing the cargo owner. These insurance
                contracts are typically structured as "all risk," offering
                comprehensive coverage, with exceptions clearly outlined in the
                policy. While filing a claim can be a complex and time-consuming
                process, our dedicated team is here to support our clients every
                step of the way.
              </p>
            </div>
            {/* Cargo Insurance End */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Ocean">
                  <i className="fa fa-angle-right me-2" />
                  Ocean Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Air">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Ground">
                  <i className="fa fa-angle-right me-2" />
                  Ground Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Project">
                  <i className="fa fa-angle-right me-2" />
                  Project Cargo
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Value Added Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Break">
                  <i className="fa fa-angle-right me-2" />
                  Break Bulk
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Hazardous">
                  <i className="fa fa-angle-right me-2" />
                  Hazardous Goods
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Cargo">
                  <i className="fa fa-angle-right me-2" />
                  Cargo Insurance
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Custom">
                  <i className="fa fa-angle-right me-2" />
                  Custom Clearance
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Trading Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Fashion
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Food
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Furniture
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Oil and Gas
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Retail
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
