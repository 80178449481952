import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">Our Services</h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">Home</Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled" >Our Services</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* Blog Start */}
            <div className="container-fluid bg-light pt-5">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col text-center mb-4">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
                What We Offer
              </h6>
              <h1 className="mb-4">Freight Solutions We Provide..</h1>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Air Freight.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Air">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">Air Freight</h5>
                  </div>
                  <p>
                    Our extensive airfreight network is carefully designed with
                    strategically positioned gateways in key markets, supported
                    by..
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Sea Freight.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Sea">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">Sea Freight</h5>
                  </div>
                  <p>
                    At the heart of our approach, we value business
                    relationships as genuine partnerships. We firmly believe
                    that nurturing a close...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Road Freight.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Road">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">Road Freight</h5>
                  </div>
                  <p>
                    We acknowledge that transportation requirements can be
                    diverse, and we are dedicated to delivering customized
                    solutions to...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Rail Freight.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Rail">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">Rail Freight</h5>
                  </div>
                  <p>
                    Our specialized rail services provide a seamless transition
                    for your shipments to rail transport, offering numerous
                    advantages...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Contarct Logistics.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Contarct">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">
                      Contarct Logistics
                    </h5>
                  </div>
                  <p>
                    We firmly believe in the strength of collaboration and are
                    dedicated to working closely with our customers, starting
                    from...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Supply Chain Solution.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Supply">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">
                      Supply Chain Solution
                    </h5>
                  </div>
                  <p>
                    In the realm of end-to-end supply chain solutions, customs
                    clearance holds a pivotal role. However, delving into the...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog End */}
      <Footer />
    </>
  );
}
