import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">
                Sea Freight
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled">
                  Sea Freight
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5 pt-4">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Sea Freight.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Sea Freight</h1>
              <p>
                At the heart of our approach, we value business relationships as
                genuine partnerships. We firmly believe that nurturing a close
                and trustful cooperation is the key to understanding your unique
                needs and collaboratively devising optimal sea freight
                solutions. Our ultimate objective is to contribute to your
                business's success by giving priority to sustainable concepts.
              </p>

              <p>
                With our extensive expertise and a well-established global
                network, we have forged robust relationships with the world's
                leading shipping companies. Coupled with our ongoing efforts to
                enhance our digital tools, we ensure the smooth transportation
                of your shipments across all the world's oceans, offering you a
                comprehensive solution from a single source.
              </p>

              <p>
                Whether you need full-container-loads (FCL),
                less-than-container-loads (LCL), or complex project shipments,
                we are well-prepared to meet your specific requirements and
                deliver your goods to their intended destination. Our services
                encompass not only transportation but also extend to crucial
                aspects like customs clearance, precise shipment documentation,
                and the meticulous handling of your valuable cargo. These
                offerings provide just a glimpse of our extensive product
                portfolio, all aimed at providing you with a comprehensive and
                efficient sea freight experience.
              </p>
            </div>
            {/* */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Our Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link to="/Air" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link to="/Sea" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Sea Freight
                </Link>
                <Link to="/Road" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Road Freight
                </Link>
                <Link to="/Rail" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Rail Freight
                </Link>
                <Link to="/Contarct" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Contarct Logistics
                </Link>
                <Link to="/Supply" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Supply Chain Solution
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
