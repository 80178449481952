import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* Carousel Start */}
      <div
        className="container-fluid p-0 w3-banner jarallax"
        style={{ top: "-55px" }}
      >
        <video autoPlay loop muted>
          <source src="img/video.mp4"></source>
        </video>
      </div>

      {/* About Start */}
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex flex-column align-items-center justify-content-center bg-primary h-100  px-3">
                <img src="img/About.jpg" style={{ width: "100%" }}></img>
              </div>
            </div>
            <div className="col-lg-6 m-0 my-lg-5 pb-lg-2 pl-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
                Learn About Us
              </h6>
              <h1 className="mb-4 section-title">
                Seamless Freight Solutions from Mumbai
              </h1>
              <p>
                At {companyname}, we take great pride in our capacity to
                deliver dependable and top-quality logistics services. Drawing
                upon our extensive experience, global network, and unwavering
                commitment to customer satisfaction, we continuously evolve to
                meet the ever-shifting demands of the industry. Our dedication
                remains steadfast in providing customers with the right
                solutions, underpinned by state-of-the-art technology and a
                strong focus on transparency and supply chain management
                efficiency.
              </p>
              <p>
                Over time, we have consistently broadened our capabilities and
                honed our expertise to align with the changing requirements of
                our valued customers. Today, we present a comprehensive suite of
                high-performance logistics products, encompassing Airfreight,
                Seafreight, Road & Rail, and Contract Logistics.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Blog Start */}
      <div className="container-fluid bg-light pt-5">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col text-center mb-4">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
                What We Offer
              </h6>
              <h1 className="mb-4">Freight Solutions We Provide..</h1>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Air Freight.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Air">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">Air Freight</h5>
                  </div>
                  <p>
                    Our extensive airfreight network is carefully designed with
                    strategically positioned gateways in key markets, supported
                    by..
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Sea Freight.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Sea">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">Sea Freight</h5>
                  </div>
                  <p>
                    At the heart of our approach, we value business
                    relationships as genuine partnerships. We firmly believe
                    that nurturing a close...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Road Freight.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Road">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">Road Freight</h5>
                  </div>
                  <p>
                    We acknowledge that transportation requirements can be
                    diverse, and we are dedicated to delivering customized
                    solutions to...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Rail Freight.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Rail">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">Rail Freight</h5>
                  </div>
                  <p>
                    Our specialized rail services provide a seamless transition
                    for your shipments to rail transport, offering numerous
                    advantages...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Contarct Logistics.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Contarct">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">
                      Contarct Logistics
                    </h5>
                  </div>
                  <p>
                    We firmly believe in the strength of collaboration and are
                    dedicated to working closely with our customers, starting
                    from...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 mb-2">
                <img
                  className="card-img-top"
                  src="img/Supply Chain Solution.jpg"
                  alt=""
                />
                <div className="card-body bg-white p-4">
                  <div className="d-flex align-items-center mb-3">
                    <Link className="btn btn-primary" to="/Supply">
                      <i className="fa fa-link" />
                    </Link>
                    <h5 className="m-0 ml-3 text-truncate">
                      Supply Chain Solution
                    </h5>
                  </div>
                  <p>
                    In the realm of end-to-end supply chain solutions, customs
                    clearance holds a pivotal role. However, delving into the...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog End */}
      {/* Features Start */}
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mt-5 py-5 pr-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
                Why Choose Us?
              </h6>
              <h1 className="mb-4 section-title">
                Mumbai's Finest Freight Forwarders
              </h1>
              <p className="mb-4">
                <p>
                  Through these diverse product offerings, we possess the
                  ability to offer customized solutions that address the
                  intricate logistics needs of our clientele. Whether it's the
                  swiftness and efficiency of airfreight, the cost-effectiveness
                  of seafreight, the adaptability of road and rail
                  transportation, or the all-encompassing management of contract
                  logistics, we hold the right solution to satisfy a variety of
                  logistical requisites.
                </p>
                <p>
                  Aligned with our commitment to innovation, we embrace
                  forward-looking digital services to elevate our operations. By
                  harnessing cutting-edge technologies, we ensure the highest
                  level of transparency and facilitate more efficient supply
                  chains. These digital solutions provide real-time tracking and
                  monitoring, streamlined processes, and enhanced visibility
                  throughout the entirety of the logistics journey.
                </p>
              </p>

              <Link to="/About" className="btn btn-primary mt-3 py-2 px-4">
                View More
              </Link>
            </div>
            <div className="col-lg-5">
              <div className="d-flex flex-column align-items-center justify-content-center h-100 overflow-hidden">
                <img className="h-100" src="img/Air Freight.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}
      {/* Projects Start */}
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col text-center mb-4">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
                Our Industries
              </h6>
              <h1 className="mb-4">Industry Solutions We Provide</h1>
            </div>
          </div>

          <div className="row mx-1 portfolio-container">
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item first p-2">
              <div className="position-relative overflow-hidden">
                <div className="portfolio-img d-flex align-items-center justify-content-center">
                  <img className="img-fluid" src="img/Fashion.jpg" alt="" />
                </div>
                <div className="portfolio-text bg-secondary d-flex flex-column align-items-center justify-content-center">
                  <h4 className="text-white mb-4">Fashion</h4>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Services"
                    >
                      <i className="fa fa-link" />
                    </Link>
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Trading"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item first p-2">
              <div className="position-relative overflow-hidden">
                <div className="portfolio-img d-flex align-items-center justify-content-center">
                  <img
                    className="img-fluid"
                    src="img/Consumer Goods.jpg"
                    alt=""
                  />
                </div>
                <div className="portfolio-text bg-secondary d-flex flex-column align-items-center justify-content-center">
                  <h4 className="text-white mb-4">Consumer Goods</h4>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Services"
                    >
                      <i className="fa fa-link" />
                    </Link>
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Trading"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item first p-2">
              <div className="position-relative overflow-hidden">
                <div className="portfolio-img d-flex align-items-center justify-content-center">
                  <img className="img-fluid" src="img/Healthcare.jpg" alt="" />
                </div>
                <div className="portfolio-text bg-secondary d-flex flex-column align-items-center justify-content-center">
                  <h4 className="text-white mb-4">Healthcare</h4>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Services"
                    >
                      <i className="fa fa-link" />
                    </Link>
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Trading"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item first p-2">
              <div className="position-relative overflow-hidden">
                <div className="portfolio-img d-flex align-items-center justify-content-center">
                  <img className="img-fluid" src="img/Industrial.jpg" alt="" />
                </div>
                <div className="portfolio-text bg-secondary d-flex flex-column align-items-center justify-content-center">
                  <h4 className="text-white mb-4">Industrial</h4>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Services"
                    >
                      <i className="fa fa-link" />
                    </Link>
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Trading"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item first p-2">
              <div className="position-relative overflow-hidden">
                <div className="portfolio-img d-flex align-items-center justify-content-center">
                  <img className="img-fluid" src="img/Perishables.jpg" alt="" />
                </div>
                <div className="portfolio-text bg-secondary d-flex flex-column align-items-center justify-content-center">
                  <h4 className="text-white mb-4">Perishables</h4>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Services"
                    >
                      <i className="fa fa-link" />
                    </Link>
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Trading"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item first p-2">
              <div className="position-relative overflow-hidden">
                <div className="portfolio-img d-flex align-items-center justify-content-center">
                  <img className="img-fluid" src="img/Technology.jpg" alt="" />
                </div>
                <div className="portfolio-text bg-secondary d-flex flex-column align-items-center justify-content-center">
                  <h4 className="text-white mb-4">Technology</h4>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Services"
                    >
                      <i className="fa fa-link" />
                    </Link>
                    <Link
                      className="btn btn-outline-primary m-1"
                      to="/Trading"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Projects End */}

      <Footer />
    </>
  );
}
