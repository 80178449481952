import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Air from "./pages/Air";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Supply from "./pages/Supply";
import Contarct from "./pages/Contarct";
import Break from "./pages/Break";
import Trading from "./pages/Trading";
import Rail from "./pages/Rail";
import Road from "./pages/Road";
import Careers from "./pages/Careers";


import Cargo from "./pages/Cargo";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Supply" element={<Supply />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Cargo" element={<Cargo />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Contarct" element={<Contarct />} />
          <Route path="/Break" element={<Break />} />
          <Route path="/Trading" element={<Trading />} />
          <Route path="/Rail" element={<Rail />} />
          <Route path="/Road" element={<Road />} />

    
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
