import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">
              About Us
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled">
                  About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex flex-column align-items-center justify-content-center bg-primary h-100  px-3">
                <img src="img/About.jpg" style={{ width: "100%" }}></img>
              </div>
            </div>
            <div className="col-lg-6 m-0 my-lg-5 pb-lg-2 pl-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
                Learn About Us
              </h6>
              <h1 className="mb-4 section-title">
                Seamless Freight Solutions from Mumbai
              </h1>
              <p>
                At {companyname}, we take great pride in our capacity to
                deliver dependable and top-quality logistics services. Drawing
                upon our extensive experience, global network, and unwavering
                commitment to customer satisfaction, we continuously evolve to
                meet the ever-shifting demands of the industry. Our dedication
                remains steadfast in providing customers with the right
                solutions, underpinned by state-of-the-art technology and a
                strong focus on transparency and supply chain management
                efficiency.
              </p>
              <p>
                Over time, we have consistently broadened our capabilities and
                honed our expertise to align with the changing requirements of
                our valued customers. Today, we present a comprehensive suite of
                high-performance logistics products, encompassing Airfreight,
                Seafreight, Road & Rail, and Contract Logistics.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mt-5 py-5 pr-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
                Why Choose Us?
              </h6>
              <h1 className="mb-4 section-title">
                Mumbai's Finest Freight Forwarders
              </h1>
              <p className="mb-4">
                <p>
                  Through these diverse product offerings, we possess the
                  ability to offer customized solutions that address the
                  intricate logistics needs of our clientele. Whether it's the
                  swiftness and efficiency of airfreight, the cost-effectiveness
                  of seafreight, the adaptability of road and rail
                  transportation, or the all-encompassing management of contract
                  logistics, we hold the right solution to satisfy a variety of
                  logistical requisites.
                </p>
                <p>
                  Aligned with our commitment to innovation, we embrace
                  forward-looking digital services to elevate our operations. By
                  harnessing cutting-edge technologies, we ensure the highest
                  level of transparency and facilitate more efficient supply
                  chains. These digital solutions provide real-time tracking and
                  monitoring, streamlined processes, and enhanced visibility
                  throughout the entirety of the logistics journey.
                </p>
              </p>

            
            </div>
            <div className="col-lg-5">
              <div className="d-flex flex-column align-items-center justify-content-center h-100 overflow-hidden">
                <img className="h-100" src="img/Air Freight.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}

      <Footer />
    </>
  );
}
