import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">
                Supply Chain Solution
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled">
                  Supply Chain Solution
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5 pt-4">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* Supply Chain Solution Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Supply Chain Solution.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Supply Chain Solution</h1>
              <p>
                In the realm of end-to-end supply chain solutions, customs
                clearance holds a pivotal role. However, delving into the
                intricacies of customs regulations, duties, fees, taxes, and
                associated procedures can be a daunting task. That's precisely
                why having a dependable partner by your side, capable of
                efficiently managing and overseeing these aspects, is of
                paramount importance. With our proficiency in customs clearance,
                CBP (Customs and Border Protection) regulations, import and
                export logistics, reconciliation, and cross-border operations,
                we are well-prepared to navigate the complexities of this
                process.
              </p>

              <p>
                We acknowledge that every client and trade regime presents
                unique requirements and considerations. Thus, we adopt a
                targeted approach, tailoring our services to align with the
                specific needs of our clients. Our experienced team is wholly
                committed to providing comprehensive support and guidance,
                ensuring the proper establishment of your trade compliance
                procedures.
              </p>

              <p>
                As an added benefit to our customers, we extend Customs
                Compliance Advisory Services. This supplementary support is
                designed to assist you with all your compliance requirements,
                guaranteeing that you remain current with the ever-evolving
                customs regulations and demands. We recognize the significance
                of upholding compliance and are dedicated to aiding you in
                navigating the intricacies of international trade.
              </p>

              <p>
                Moreover, we place a premium on transparency and visibility
                throughout the brokerage entry process. Leveraging our real-time
                visibility capabilities, you gain complete insight into the
                status and progression of your customs clearance. This provides
                you with peace of mind and confidence in the entire process.
              </p>

              <p>
                By selecting us as your customs clearance partner, you can place
                your trust in our extensive knowledge, experience, and
                unwavering dedication to ensuring smooth and compliant customs
                procedures. We are here to assist you at every juncture,
                delivering efficient customs clearance services while offering
                invaluable advisory support to enhance your trade compliance
                procedures.
              </p>
            </div>
            {/* Supply Chain Solution End */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Our Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link to="/Air" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link to="/Sea" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Sea Freight
                </Link>
                <Link to="/Road" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Road Freight
                </Link>
                <Link to="/Rail" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Rail Freight
                </Link>
                <Link to="/Contarct" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Contarct Logistics
                </Link>
                <Link to="/Supply" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Supply Chain Solution
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
