import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">
                Air Freight
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled">
                  Air Freight
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5 pt-4">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Air Freight.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Air Freight</h1>
              <p>
                Our extensive airfreight network is carefully designed with
                strategically positioned gateways in key markets, supported by
                efficient local operations. This comprehensive approach allows
                us to offer exceptional airfreight services on a global scale.
              </p>

              <p>
                We take great pride in delivering prompt and knowledgeable
                customer service. To ensure we not only meet but exceed your
                expectations, our offices are conveniently located on-site. This
                proximity enables us to provide quick responses and personalized
                assistance for any inquiries or concerns you may have.
              </p>

              <p>
                We understand that different industries have unique
                requirements. Drawing from our wealth of experience, we have
                developed specialized solutions tailored to the specific needs
                of the Healthcare, Automotive, Perishables, and Fashion sectors.
                These industry-specific solutions are meticulously crafted to
                ensure optimized handling, secure transportation, and timely
                delivery, safeguarding the integrity and safety of your valuable
                goods.
              </p>

              <p>
                Our commitment is to align our airfreight operations with global
                standards and industry best practices. We aspire to be your
                preferred choice for airfreight logistics. Be assured that our
                dedication to excellence and the provision of top-notch services
                are always at the forefront of our operations, guaranteeing a
                seamless and reliable experience for our valued customers across
                the globe.
              </p>
            </div>
            {/* */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Our Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link to="/Air" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link to="/Sea" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Sea Freight
                </Link>
                <Link to="/Road" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Road Freight
                </Link>
                <Link to="/Rail" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Rail Freight
                </Link>
                <Link to="/Contarct" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Contarct Logistics
                </Link>
                <Link to="/Supply" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Supply Chain Solution
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
