import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">
                Road Freight
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled">
                  Road Freight
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5 pt-4">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Road Freight.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Road Freight</h1>
              <p>
                We acknowledge that transportation requirements can be diverse,
                and we are dedicated to delivering customized solutions to
                fulfill your specific needs, whether it involves direct
                transport (FTL/FCL) or groupage (LTL/LCL). Through the
                application of innovative technologies and the expertise of our
                exceptional staff, we have the capability to provide
                transportation solutions that align precisely with your
                requirements.
              </p>

              <p>
                Opting for our services offers several significant advantages.
                Foremost, we prioritize swift transit times to ensure your
                shipments promptly reach their destinations. We also assure
                reliable capacities, meaning that we can cater to your
                transportation needs without any delays or capacity limitations.
                Furthermore, our extensive range of products enables us to
                accommodate various cargo types and sizes, ensuring flexibility
                in meeting your particular transportation demands.
              </p>

              <p>
                Furthermore, our commitment to service excellence is unmatched.
                We take pride in delivering service quality that is second to
                none, guaranteeing that your goods receive the utmost care and
                attention throughout the entire transportation process. Our
                fleet of trucks operates diligently day in and day out, ensuring
                the efficient and cost-effective delivery of your goods.
              </p>

              <p>
                With our comprehensive transportation solutions,
                state-of-the-art technology, and dedicated personnel, we are
                well-prepared to offer you seamless and dependable
                transportation services tailored to your precise requirements.
              </p>
            </div>
            {/* */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Our Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link to="/Air" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link to="/Sea" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Sea Freight
                </Link>
                <Link to="/Road" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Road Freight
                </Link>
                <Link to="/Rail" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Rail Freight
                </Link>
                <Link to="/Contarct" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Contarct Logistics
                </Link>
                <Link to="/Supply" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Supply Chain Solution
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
