import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  
{/*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/}

  return (
    <>
    {/* Topbar Start */}
    <div className="container-fluid bg-dark py-3">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-lg-left mb-2 mb-lg-0">
                <div className="d-inline-flex align-items-center">
                  <Link className="text-white pr-3" to="/Privacy">Privacy</Link>
                  <span className="text-white">|</span>
                  <Link className="text-white px-3" to="/Terms">Terms</Link>
                  <span className="text-white">|</span>
                  <Link className="text-white pl-3" to="/Contact">Contact</Link>
                </div>
              </div>
              <div className="col-md-6 text-center text-lg-right">
                <div className="d-inline-flex align-items-center">
                <Link to="/Getquote" className="btn btn-primary px-4">
                Request A Quote
              </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <div className="container-fluid position-relative nav-bar p-0">
          <div className="container position-relative" style={{zIndex: 9}}>
            <nav className="navbar navbar-expand-lg bg-light navbar-dark py-3 py-lg-0 pl-3 pl-lg-5">
              <Link to="/" className="navbar-brand">
                <img src="img/logo.png" style={{height:"90px"}}></img>
              </Link>
              <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                <i className="fa fa-2x fa-bars" style={{color:"#18394d"}}/>
              </button>
              <div className="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
                <div className="navbar-nav ml-auto py-0">
                  <Link to="/" className="nav-item nav-link ">Home</Link>
                  <Link to="/About" className="nav-item nav-link">Who We Are</Link>
                  <div className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown">What We Do</Link>
                    <div className="dropdown-menu rounded-0 m-0" >
                      <Link to="/Air" className="dropdown-item">Air Freight</Link>
                      <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                      <Link to="/Road" className="dropdown-item">Road Freight</Link>
                      <Link to="/Rail" className="dropdown-item">Rail Freight</Link>
                      <Link to="/Contarct" className="dropdown-item">Contarct Logistics</Link>
                      <Link to="/Supply" className="dropdown-item">Supply Chain Solution</Link>
                    </div>
                  </div>
                  <div className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Industries</Link>
                    <div className="dropdown-menu rounded-0 m-0">
                      <Link to="/Trading" className="dropdown-item">Fashion</Link>
                      <Link to="/Trading" className="dropdown-item">Consumer Goods</Link>
                      <Link to="/Trading" className="dropdown-item">Healthcare</Link>
                      <Link to="/Trading" className="dropdown-item">Industrial</Link>
                      <Link to="/Trading" className="dropdown-item">Perishables</Link>
                      <Link to="/Trading" className="dropdown-item">Technology</Link>
                    </div>
                  </div>
                  <Link to="/Contact" className="nav-item nav-link">Contact</Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
        {/* Navbar End */}
    </>
  );
};

export default Header;
