import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">
                Rail Freight
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled">
                  Rail Freight
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5 pt-4">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Rail Freight.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Rail Freight</h1>
              <p>
                Our specialized rail services provide a seamless transition for
                your shipments to rail transport, offering numerous advantages
                in terms of efficiency, cost-effectiveness, and environmental
                impact. Through the utilization of rail transportation, we
                achieve remarkable efficiency, leading to cost savings and a
                reduction in CO2 emissions.
              </p>

              <p>
                In addition to our rail services connecting dispatch and
                receiving stations, we manage all pre- and post-carriage
                logistics as part of our integrated transport solutions. This
                means that we take care of transporting your goods from the
                point of origin to the rail terminal and from the rail terminal
                to the final destination, ensuring a comprehensive end-to-end
                service.
              </p>

              <p>
                By harnessing rail transport, we significantly diminish the
                ecological footprint associated with traditional road
                transportation. Rail transport is renowned for its environmental
                benefits, as it produces fewer CO2 emissions compared to other
                transportation methods. Our commitment to sustainability is
                evident in our choice of rail services, allowing us to
                contribute to global efforts to reduce carbon emissions and
                endorse more environmentally friendly logistics solutions.
              </p>

              <p>
                Moreover, our rail services deliver outstanding cost efficiency.
                Rail transportation is often more economical than road transport
                for long-distance haulage, enabling us to pass these cost
                savings on to our customers. This economic advantage, coupled
                with the environmental benefits, makes our specialized rail
                services an appealing and sustainable option for your
                transportation requirements.
              </p>

              <p>
                With our proficiency in rail logistics and our dedication to
                offering efficient, cost-effective, and eco-friendly solutions,
                we ensure that your shipments receive the utmost care and reach
                their destinations seamlessly, all while minimizing the impact
                on the environment.
              </p>
            </div>
            {/* */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Our Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link to="/Air" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link to="/Sea" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Sea Freight
                </Link>
                <Link to="/Road" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Road Freight
                </Link>
                <Link to="/Rail" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Rail Freight
                </Link>
                <Link to="/Contarct" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Contarct Logistics
                </Link>
                <Link to="/Supply" className="h6 text-uppercase mb-4">
                  <i className="fa fa-angle-right me-2" />
                  Supply Chain Solution
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
