import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      {/* Footer Start */}
      <div className="container-fluid bg-dark text-white py-5 px-sm-3 px-md-5">
        <div className="row pt-5">
          <div className="col-lg-3 col-md-6 mb-5">
            <h4 className="text-primary mb-4">Get In Touch</h4>
            <p>
              <i className="fa fa-map-marker-alt mr-2" />
             {companyaddress}
            </p>
            <p>
              <i className="fa fa-phone-alt mr-2" />
              {companynumber}
            </p>
            <p>
              <i className="fa fa-envelope mr-2" />
              {companyemail}
            </p>
            
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <h4 className="text-primary mb-4">Services Links</h4>
            <div className="d-flex flex-column justify-content-start">
             
              <Link to="/Air" className="text-white mb-2">
                <i className="fa fa-angle-right mr-2" />
                Air Freight
              </Link>
              <Link to="/Sea" className="text-white mb-2">
                <i className="fa fa-angle-right mr-2" />
                Sea Freight
              </Link>
              <Link to="/Road" className="text-white mb-2">
                <i className="fa fa-angle-right mr-2" />
                Road Freight
              </Link>
              <Link to="/Rail" className="text-white mb-2">
                <i className="fa fa-angle-right mr-2" />
                Rail Freight
              </Link>
              <Link to="/Contarct" className="text-white mb-2">
                <i className="fa fa-angle-right mr-2" />
                Contarct Logistics
              </Link>
              <Link to="/Supply" className="text-white mb-2">
                <i className="fa fa-angle-right mr-2" />
                Supply Chain Solution
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <h4 className="text-primary mb-4">Popular Links</h4>
            <div className="d-flex flex-column justify-content-start">
              <Link className="text-white mb-2" to="/">
                <i className="fa fa-angle-right me-2" />
                Home
              </Link>
              <Link className="text-white mb-2" to="/About">
                <i className="fa fa-angle-right me-2" />
                About Us
              </Link>
              <Link className="text-white mb-2" to="/Services">
                <i className="fa fa-angle-right me-2" />
                Our Services
              </Link>
              <Link className="text-white mb-2" to="/Trading">
                <i className="fa fa-angle-right me-2" />
                Trading Services
              </Link>
              <Link className="text-white mb-2" to="/Contact">
                <i className="fa fa-angle-right me-2" />
                Contact Us
              </Link>
              <Link className="text-white mb-2" to="/Getquote">
                <i className="fa fa-angle-right me-2" />
                Get A Quote
              </Link>
              <Link className="text-white mb-2" to="/Terms">
                <i className="fa fa-angle-right me-2" />
                Terms
              </Link>
              <Link className="text-white mb-2" to="/Privacy">
                <i className="fa fa-angle-right me-2" />
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <h4 className="text-primary mb-4">Newsletter</h4>
            <form
                  action="/php/thankyou-subscribe.php "
                  method="POST"
                  onSubmit={(event) => handleSubmit(event)}
                 
                >   <div className="form-group">
                  <input
                    className="form-control border-0"
                    onChange={(event) => handleSubemailChange(event)}
                    id="subemail"
                    value={subemail}
                    type="email"
                    name="subemail"
                    placeholder="Your email address"
                    required
                  /></div>
                  <button className="btn btn-lg btn-primary btn-block border-0" type="submit">
                    Subscribe
                  </button>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
           
          </div>
        </div>
        <div className="container border-top border-secondary pt-5">
          <p className="m-0 text-center text-white">
            ©2023{" "}
            <Link className="text-white font-weight-bold" to="/">
              {companyname}
            </Link>
            . All Rights Reserved.
          </p>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default Footer;
