import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-banner py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="mb-4 mb-md-0 text-primary text-uppercase">Industries</h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-outline-primary" to="/">Home</Link>
                <i className="fas fa-angle-double-right text-primary mx-2" />
                <Link className="btn btn-outline-primary disabled" >Industries</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container p-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex flex-column align-items-center justify-content-center bg-primary h-100  px-3">
              <img src="img/Fashion.jpg"  style={{ width:"100%"}}></img>
              </div>
            </div>
            <div className="col-lg-6 m-0 my-lg-5 pb-lg-2 pl-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
              Industries
              </h6>
              <h1 className="mb-4 section-title">
              Fashion
              </h1>
              <p>
               


In the ever-evolving realm of the fashion industry, the key to success lies in maintaining a flexible supply chain. This adaptability is of paramount importance due to factors such as globalization, digitalization, rapidly changing trends, and unforeseen events like the Covid-19 pandemic. Retailers are responding by embracing versatile omnichannel strategies and placing greater emphasis on e-commerce.

{companyname} Fashion Logistics understands the significance of tailoring an integrated supply chain strategy for fashion businesses, giving them a competitive edge. Our expertise lies in optimizing your supply chain, making it faster, more efficient, and adaptable. Our aim is to offer personalized solutions for replenishment, warehousing, and transportation, enabling you to swiftly respond to the fast-paced demands of the industry.
              </p>
             
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container p-4">
          <div className="row">
           
            <div className="col-lg-6 m-0 my-lg-5 pb-lg-2 pl-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
              Industries
              </h6>
              <h1 className="mb-4 section-title">
              Consumer Goods
              </h1>
              <p>
              The prosperity of consumer goods manufacturers is increasingly reliant on establishing a dependable supply chain. {companyname} Consumer Goods Logistics specializes in delivering solutions that make your supply chain swifter, more reliable, and transparent. We offer end-to-end logistics solutions specifically designed for the consumer goods and FMCG (Fast-Moving Consumer Goods) industry.

Our comprehensive range of services includes tailored warehousing concepts to ensure the efficient storage and distribution of your products. We enhance your supply chain by harnessing our four modes of transport - air, sea, road, and rail - to create the most efficient and cost-effective transportation solutions for your goods. With state-of-the-art IT systems, we offer maximum transparency across the entire supply chain, enabling real-time tracking and monitoring of your shipments.


              </p>
             
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-column align-items-center justify-content-center bg-primary h-100  px-3">
              <img src="img/Consumer Goods.jpg"  style={{ width:"100%"}}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container p-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex flex-column align-items-center justify-content-center bg-primary h-100  px-3">
              <img src="img/Healthcare.jpg"  style={{ width:"100%"}}></img>
              </div>
            </div>
            <div className="col-lg-6 m-0 my-lg-5 pb-lg-2 pl-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
              Industries
              </h6>
              <h1 className="mb-4 section-title">
              Healthcare
              </h1>
              <p>
              The healthcare industry demands specialized and highly regulated logistics processes to uphold the quality, efficacy, and integrity of its products. {companyname} comprehends these unique requirements and is dedicated to providing global logistics solutions that adhere to the stringent standards of the healthcare sector.

We exercise control over our scalable operational processes by adhering to regulatory-compliant quality standards. This commitment allows us to offer reliable supply chain solutions on a global scale. We collaborate closely with our customers to craft tailored concepts that secure the temperature-controlled transportation, storage, packaging, and additional value-added services for their healthcare products.


              </p>
             
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container p-4">
          <div className="row">
           
            <div className="col-lg-6 m-0 my-lg-5 pb-lg-2 pl-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
              Industries
              </h6>
              <h1 className="mb-4 section-title">
              Industrial
              </h1>
              <p>
              Industrial manufacturers recognize the pivotal role of a reliable and cost-efficient supply chain in achieving economic success. At {companyname}, our Industrial Logistics experts possess profound industry knowledge and extensive product expertise. This expertise empowers us to devise innovative end-to-end solutions for your supply chain processes.

From managing inbound logistics to handling reverse logistics, we provide tailored concepts for warehousing, contract logistics, and optimal transportation solutions. We leverage the strengths of our four modes of transport (air, sea, road, and rail) to ensure that we support you throughout the entire lifecycle of your products, delivering seamless and efficient logistics solutions.
              </p>
             
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-column align-items-center justify-content-center bg-primary h-100  px-3">
              <img src="img/Industrial.jpg"  style={{ width:"100%"}}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container p-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex flex-column align-items-center justify-content-center bg-primary h-100  px-3">
              <img src="img/Perishables.jpg"  style={{ width:"100%"}}></img>
              </div>
            </div>
            <div className="col-lg-6 m-0 my-lg-5 pb-lg-2 pl-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
              Industries
              </h6>
              <h1 className="mb-4 section-title">
              Perishables
              </h1>
              <p>
              When it comes to transporting perishable goods, whether they are tropical fruits, seafood, flowers, or berries, we are committed to providing comprehensive support across the entire supply chain. Our unwavering goal is to offer top-tier service that preserves the integrity and freshness of your perishable products.

We comprehend the unique requirements and challenges of transporting perishable goods, which is why we consistently invest in the latest equipment and expand our global network. Our dedicated employees worldwide are highly trained to handle perishable goods professionally and with the utmost care. This enables us to manage the refrigerated supply chain entirely, ensuring reliability and efficiency from start to finish.


              </p>
             
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container p-4">
          <div className="row">
           
            <div className="col-lg-6 m-0 my-lg-5 pb-lg-2 pl-lg-5">
              <h6 className="text-primary font-weight-normal text-uppercase mb-3">
              Industries
              </h6>
              <h1 className="mb-4 section-title">
              Technology
              </h1>
              <p>
              In the fiercely competitive global tech market, we understand that flexible, secure, reliable, and cost-effective logistics solutions are essential for success. At {companyname}, our dedicated Technology Logistics team is here to cater to your specific needs and provide comprehensive support for the worldwide transportation of high-tech and consumer electronics.

Our expertise spans a wide array of tech products, including computers, laptops, semiconductors, components, smartphones, printers, TVs, gaming consoles, and accessories. Additionally, we have the capability to handle the shipping of large household appliances, also known as white goods, such as air conditioning systems, refrigerators, freezers, washing machines, dishwashers, and more.


              </p>
             
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-column align-items-center justify-content-center bg-primary h-100  px-3">
              <img src="img/Technology.jpg"  style={{ width:"100%"}}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
 

      <Footer />
    </>
  );
}
